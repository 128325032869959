import { reduce } from 'lodash';
import { core, handleError } from 'novapay-ui';

const getRootTypes = () => core.getTypes('root').namespaced;

const defaultHandlers = {
	emptyResponse: (context) => context.commit(getRootTypes().ERROR, { code: 500 }, { root: true }),
	forbidden: (context, res) => {
		if (res?.data?.unauthorized) {
			return context.commit(getRootTypes().ERROR, { code: 403 }, { root: true });
		}
		return context.commit(getRootTypes().APP_DATA_ERROR, null, { root: true });
	},
	processing: (context, res, processingType) => {
		if (processingType) {
			context.commit(processingType, res.data);
		}
		context.commit(
			getRootTypes().ERROR_ALERT,
			{
				text: res.data.error,
				title: res.data.title,
				code: res.data.code
			},
			{ root: true }
		);
		return false;
	},
	validation: (context, res, type) => context.commit(type, res.data.errors),
	wantedStatus: (context, res, type) => {
		if (res.status === 499) {
			// client cancelled request
			return false;
		}
		let { uuid } = (res && res.data) || {};
		context.commit(getRootTypes().ERROR, { code: res.status || 500, uuid }, { root: true });
	}
};

export default (handlers) => {
	// in case you want to cover all cases with a single callback or use defaults
	let hds =
		typeof handlers === 'function'
			? reduce(defaultHandlers, (res, val, key) => ({ ...res, [key]: handlers }), {})
			: handlers || {};
	return handleError({
		...defaultHandlers,
		...hds
	});
};
